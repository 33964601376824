exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-project-jsx": () => import("./../../../src/pages/project.jsx" /* webpackChunkName: "component---src-pages-project-jsx" */),
  "component---src-pages-project-project-slug-jsx": () => import("./../../../src/pages/project/{project.slug}.jsx" /* webpackChunkName: "component---src-pages-project-project-slug-jsx" */),
  "component---src-pages-service-jsx": () => import("./../../../src/pages/service.jsx" /* webpackChunkName: "component---src-pages-service-jsx" */),
  "component---src-pages-service-service-slug-jsx": () => import("./../../../src/pages/service/{service.slug}.jsx" /* webpackChunkName: "component---src-pages-service-service-slug-jsx" */)
}

